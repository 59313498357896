import { createApp } from 'vue'
import App from './App.vue'
import ElementPlus from 'element-plus'
import * as ElIconModules from '@element-plus/icons-vue'
import router from './utils/router'
import 'element-plus/dist/index.css'
let Vue=createApp(App)
Vue.use(router)
Vue.use(ElementPlus)



// 统一注册Icon图标
for (const iconName in ElIconModules) {
    if (Reflect.has(ElIconModules, iconName)) {
        const item = ElIconModules[iconName]
        Vue.component(iconName, item)
    }
}
Vue.mount('#app')
